@charset 'UTF-8';
.subpage-mv{
	color: #fff; 
	position: relative;
	line-height: 1;
	padding: 70px 0;
	margin-top: 30px;
	z-index: -1;
	@include SP {
		padding: 30px 0 30px;
		margin-top: 103px;
	}
	&:before{
		@include gradient-mv;
		@include absolute(46%,100%,0,'',0,0);
		z-index: -1;
		border-radius: 0px 20px 20px 0px;
		@include SP {
			width: 94%;
		} 
	}
	&:after{
		@include absolute(75.62%,220px,-30px,0,0,'');
		background: url(../img/common/header/img_mv.jpg);
		background-size: cover;
		z-index: -2;
		@include SP {
			background: url(../img/common/header/img_mv-sp.jpg);
			background-size: cover;
			width: 100%;
			height: 135px;
			top: -103px;
		}
	}
	&__en {
		@include brandon;
		@include font-size(54);
		font-weight: bold;
		letter-spacing: 0.1em;
		@include SP {
			@include font-size(28);
		}
	}
	&__ja {
		margin-top: 10px;
		letter-spacing: 0.18em;
		@include font-size(20);
		font-weight: 500;
		@include SP {
			@include font-size(14);
		}
	}
}

.breadcrumb{
	@include flexbox;
    @include flex-wrap;
    @include justify-content(flex-start);
    @include font-size(12);
    padding: 20px 20px 80px;
    @include SP {
    	 padding: 10px 20px 40px;
    }
    li {
    	padding-right: 15px;
    	&:last-child {
    		padding-right: 0;
    	}
    	a {
    		text-decoration: underline;
    		position: relative;
    		padding-right: 15px;
    		&:after{
    			@include absolute(6px,6px,6px,0,'','');
	            display: inline-block;
	            border: solid $color_body;
	            border-width: 0 1px 1px 0;
	            transform: rotate(-45deg);
	            -webkit-transform: rotate(-45deg);
    		}
    	}
    }
}

a.btn-common {
	line-height: 3.75;
	display: block;
	margin: auto;
	border-radius: 5px;
	width: 276px;
	max-width: 100%;
	text-align: center;
	letter-spacing: 1px;
	color: #fff;
	font-weight: bold;
    background: #FFB100;
    box-shadow: 0px 0px 10px rgba(116, 77, 37, 0.4);
    transition: all .3s ease;
    position: relative;
	&:after {
		@include absolute(30px,1px,50%,0,'',''); 
		border-top: 1px #fff solid;
		transition: all .3s ease;
		@include SP {
			width: 23px;
		}
	}
	@include HOVER {
		&:hover {
		  box-shadow: none;
		  opacity: 1;
		  &:after {
		  	width: 11px;
		  }	
		}
	}
}

.columnpc {
	@include PC {
		@include flexbox;
	    @include flex-wrap;
	    @include justify-content(space-between);
	}
}

@charset 'UTF-8';
.footer-row {
	@include PC {
		@include flexbox;
        @include flex-wrap;
        @include justify-content(space-between);
	}
		
}
.footer {
	background: #F2FAFF;
	padding: 40px 0 10px;
	@include SP {
		padding-top: 30px;
	}
	&--inner {
	}
	&__01{
		.footermain {
			@include PC {
				width: 33%;
			}
			&__logo{
				@include SP {
					text-align: center;
				}
			}
			&__address {
				margin-top: 10px;
				@include SP {
					text-align: center;
					margin-top: 20px;
					margin-bottom: 20px;
					position: relative;
					padding-bottom: 55px;
					a {
						text-decoration: underline;
					}
				}
			}
		}//footermain
		.footernav {
			@include font-size(14);
			width:55%;
			margin-top: 30px;	
        	li {
        		a {
        			display: block;
        			padding: 3px 0;
        			position: relative;
        			padding-left: 15px;
        			&:before{
        				@include absolute(5px,1px,50%,'','',0);
        				display: inline-block;
		                border: solid currentColor;
		                border-width: 0 0 1px 1px;
        			}
        			&.sub{
        				padding-left: 30px;
	        			&:before {
	        			  height: 5px;
	        			  left: 15px;
	        			  top: 10px;
		                }
	        		}//sub
        		}//a

        	}
		}//footernav
	}//__01
	&__02{
		border-top: 1px solid #c1e4fb;
        padding-top: 40px;
        margin-top: 40px;
		margin-bottom: 35px;
		.footer-address {
			width: 20%;
		}
	}//__02
	&__03{
		@include justify-content(flex-start);
		a {
			display: block;
			@include PC {
				margin-right: 15px;
				&.btn-common {
					display: none;
				}	
			}
			@include SP {
				text-align: center;
				margin: 10px auto;
				&.btn-common {
					max-width: 260px;
					margin-top: 30px;
					margin-bottom: 25px;
				}
			}
			img {
				width: 275px;
				transition: 0.3s ease;
				box-shadow: 0px 0px 10px rgba(68, 120, 165, 0.16); 
			}
			@include HOVER {
				&:hover {
					opacity: 1;
					img {
						box-shadow: none;
					}
				}
			}
		}
	}//__03 
	
	.footer-address {
		p {
			@include font-size(14);
			&.txt12 {
				@include font-size(12);
				margin-top: 5px;
				.map {
					text-decoration: underline;
					background: url(../img/common/footer/ico_map.svg) no-repeat left center;
					padding-left: 12px;
					display: inline-block;
					@include PC {
						margin-left: 5px;
					}
					@include brandon;
					@include font-size(13);
					@include SP {
						@include absolute(90px,35px,'','',0,calc(50% - 45px));
						background: $color_body url(../img/common/footer/ico_map-sp.svg) no-repeat 25px center;
						display: inline-block;
						padding-left: 10px;
						text-align: center;
						line-height: 35px;
						color: #fff;
					}
				}
			}
		}
	}
	.copyright {
		margin-top: 10px;
		text-align: right;
		@include font-size(12);
		@include SP {
			margin-top: 50px;
			text-align: center;
		}
	} 
	@include SP {
		.footernav {
			display: none;
		}
		&__02 {
			display: none;
		}	
	}
} 
.totop{
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 5;
  width: 50px;
  height: 50px;
  transition: 0.3s ease;
  cursor: pointer;
  background: url(../img/common/footer/btn_ptop.svg) no-repeat center center;
  box-shadow: 0px 0px 10px rgba(68, 120, 165, 0.16);
  z-index: 9998;
  @include HOVER {
  	&:hover{
	  	box-shadow:none;
	  }
  }
  @include SP {
  	bottom: 10px;
  }
  &.off{
    @include transform_c(translateY(200%));
  }
}

#top {
	.footer {
		@include PC {
			padding-top: 110px;
		}
	}	
}
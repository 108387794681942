@charset 'UTF-8';
.header{
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  transition: all .3s ease;
  z-index: 9999; 
  @include SP {
    height: 70px;
  }
  &__inner {
    padding: 16px 14px;
    @include flexbox;
    @include flex-wrap;
    @include justify-content(space-between);
    @include SP {
      padding: 10px 10px;
    }
    #logo {
      transition: all .3s ease;
      margin-top: -10px;
      margin-left: 20px;
      @include SP {
        margin-top: -5px;
        margin-left: 10px;
      }
      img {
        width: 57px;
        height: 90px;
        @include SP {
          width: 32px;
          height: 51px;
        }
      }
    }
    @include PC {
      nav {
        @include flexbox;
        @include flex-wrap;
        line-height: 3;
        height: 48px; 
        ul {
          @include flexbox;
          @include flex-wrap;
          @include justify-content(flex-end);
          font-weight: 500;
          > li {
            margin: 0 14px;
            &.dropdown {
              cursor: pointer;
              position: relative;
              a {
                padding-right: 15px;
                padding-bottom: 13px;
                position: relative;
                &:after {
                  @include absolute(6px,6px,8px,0,'','');
                  display: inline-block;
                  transition: all 0.6s;
                  border: solid #2EA3ED;
                  border-width: 0 1px 1px 0;
                  transform: rotate(45deg);
                  -webkit-transform: rotate(45deg); 
                }
              }
              ul {
                height: 0;
                left: 0;
                opacity: 0;
                position: absolute;
                transition: all .3s ease;
                bottom: -11px;
                width: 215px;
                pointer-events: none;
                left: calc(50% - 107px);
                padding-top: 11px; 
                &:before{
                    content: " ";
                    position: absolute;
                    top: 3px;
                    left: calc(50% - 4px);
                    z-index: -1;
                    width: 0;
                    height: 0; 
                    border-style: solid;
                    border-width: 0 3px 8px 3px;
                    border-color: transparent transparent #166DD9 transparent;
                  }
                  li {
                    width: 100%;
                    margin-bottom: 1px;
                    @include font-size(14);
                    position: relative;
                    &:after {
                      @include absolute(21px,1px,50%,0,'',''); 
                      border-top: 1px #fff solid;
                    }
                    &:first-child{
                      a {
                        border-radius: 5px 5px 0 0;
                      }  
                    }
                    &:last-child {
                      a {
                        border-radius: 0 0 5px 5px;
                      }  
                    }
                    a {
                      color: #fff;
                      display: block;
                      line-height: 2.7;
                      padding: 0 15px;
                      @include gradient-adva;
                    }/*a*/
                  }/*li*/ 
              }
              @include PC {
                &:hover {
                  > a {
                    border-bottom: 2px solid #2EA3ED;
                    color: #2EA3ED;
                    opacity: 1;
                    &:after {
                      top: 12px;
                      transform: rotate(-135deg);
                      -webkit-transform: rotate(-135deg);
                    }
                  }
                  ul {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: auto;
                  }/*ul*/
                } /*hover*/ 
              }  
            }/* dropdown */
          }/*> li*/
        }/*> ul*/
      }/* nav */
      .hamberger {
        display: none;
      }
    } /* PC */
    @include SP {
      .wrapnav {
        @include transform_c(translateX(100%));
        position: fixed;
        z-index: 9999;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0; 
        background: url(../img/common/header/bg_header.png);
        transition: all .3s ease;
        overflow: auto;
        nav {
          width: 80%;
          height: 100%;
          position: fixed;
          right: 0;
          background: -moz-linear-gradient(top, #2ea3ed 0%, #1f81e1 42.36%, #166bd9 70.94%, #2389e4 94.09%, #2ea3ed 100%);
          background: -webkit-linear-gradient(top, #2ea3ed 0%, #1f81e1 42.36%, #166bd9 70.94%, #2389e4 94.09%, #2ea3ed 100%);
          background: linear-gradient(to bottom,#2ea3ed 0%, #1f81e1 42.36%, #166bd9 70.94%, #2389e4 94.09%, #2ea3ed 100%);
          padding-top: 65px;
          padding-bottom: 25px;
          overflow: auto;
          > ul {
            border-bottom: #479CD1 solid 1px;
            @include font-size(14);
            font-weight: 500;
            li {
              border-top: #479CD1 solid 1px;
              a {
                color: #fff;
                padding: 16px 0 16px 30px;
                display: block; 
              }
              ul {
                li {
                  padding-left: 20px;
                  a{ 
                    position: relative;
                    &:before {
                      content: "-";
                      position: absolute;
                      left: 10px;
                      top: 15px;
                      color: currentColor;
                    }
                  }
                }
              }
            }
            > li {
              &:first-child {
                border-top: none;
              }
            }
            a {
              position: relative;
              &:after{
                @include absolute(5px,5px,calc(50% - 3px),20px,'','');
                border: solid #fff;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
              }
            }
          }/*ul*/
          .privacylink {
            text-align: center;
            @include font-size(12);
            a {
              text-decoration: underline;
              color: #fff;
            }
          }
        }/*nav*/
       } /*wrapnav*/
      .hamberger {
        width: 50px;
        height: 50px;
        background: #2EA3ED;
        text-align: center;
        border-radius: 5px;
        position: relative;
        z-index: 9999;
        i {
          width: 20px;
          height: 2px;
          line-height: 0;
          background:#fff;
          display: block;
          position: absolute;
          top: 50%;
          left: 15px;
          @include add_prefix(transition, 0.3s ease all);
          &:first-child {
            top: calc(50% - 7px;);
          }
          &:last-child {
            top: calc(50% + 7px;);
          }
        }
        &.active {
          background: none;
          top: -13px;
          i {
            width: 24px;
            &:first-child {
              @include transform_c(rotate(45deg) translateY(7px) translateX(7px));
              top: calc(50% - 10px);
            }
            &:last-child {
              @include transform_c(rotate(-45deg));
              top: 50%;
            }
            &:nth-child(2){
              display: none;
            }  
          }
        }
      }/*hamberger*/
    }/*SP*/ 
  } /*__inner*/
  
  &__btn {
    @include PC {
      margin-left: 30px;
    }
    @include ipad {
      margin-left: 0;
    }
    a {
      line-height: 3;
      display: inline-block;
      border-radius: 5px;
      width: 212px;
      text-align: center;
      position: relative;
      letter-spacing: 1px;
      font-weight: bold;
      box-shadow: 0px 0px 6px rgba(116, 77, 37, 0.2);
      transition: all .3s ease;
      @include ipad {
        width: 180px;
      }  
      &:after {
        @include absolute(14px,1px,50%,0,'',''); 
        border-top: 1px #fff solid;
        transition: all .3s ease;
      }
      @include HOVER {
        &:hover {
          box-shadow: none;
          opacity: 1;
          &:after{
            width: 7px;
          }
        }
      }
    }
    .btn01 {
      color: #FFB100;
      background: #fff;
      @include PC {
        margin-right: 10px; 
      }
      &:after {
        border-color: #FFB100;
      }  
    }
    .btn02 {
      color: #fff;
      background: #FFB100;
    } 
    @include SP {
      margin: 20px 20px 15px;
      @include flexbox;
      @include flex-wrap;
      flex-direction: column-reverse;
      a {
        width: 100%;
        line-height: 3.75;
        box-shadow: 0px 0px 7px rgba(116, 77, 37, 0.4);
        margin-bottom: 10px;
        font-weight: bold;
        &:after {
          width: 23px;
        }   
      }
    }
  }/*__btn*/
}/* header */

@include SP {
  .layerOn {
    overflow: hidden;
    height: 100%;
    .wrapnav {
      display: block;
      @include transform_c(translateX(0));
    }
  } /*layerOn*/
}/*SP*/
.fix-header {
  position: relative;
  .header {
    background: #fff;
    #logo {
      @include PC {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
} 
@include PC {
  #top {
    .header {
      #logo {
        margin-top: 0;
        img {
          width: 75px;
          height: 115px;
        }
      }
    }
  }
}



